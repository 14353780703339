.crpt-logo {
	background-image: url(./logo.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x: center;
	background-color: #fcff25;
}

.crpt-load {
	background-image: url(./loadbtn.gif);
	background-repeat: no-repeat;
	background-position: center;
	height: 28pt;
}

.crpt-submit:disabled {
	background-color: #faf995 !important;
}
